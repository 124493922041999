import Echo from 'laravel-echo';

export default (context, inject) => {
  window.Pusher = require('pusher-js');

  const echo = new Echo({
    broadcaster: 'pusher',
    key: 'seomaster',
    cluster: 'mt1',
    wsHost: window.location.hostname,
    wsPort: 6001,
    forceTLS: false,
    disableStats: true,
    authorizer: (channel, options) => {
      return {
        authorize: (socketId, callback) => {
          context.$axios.post('/api/broadcasting/auth', {
            socket_id: socketId,
            channel_name: channel.name
          })
            .then(response => {
              callback(false, response.data);
            })
            .catch(error => {
              callback(true, error);
            });
        }
      };
    },
  });

  inject('echo', echo);
};
