import * as types from '../types';
import { generator } from '@@/plugins/backend';

export default {
  async fetchUser ({ commit }) {
    try {
      const user = await this.$axios.$get(generator('auth.me'));

      commit(types.FETCH_USER_SUCCESS, { user });
    } catch (e) {
      console.error(e);
      commit(types.FETCH_USER_FAILURE);
    }
  },

  async logout ({ commit }) {
    try {
      await this.$axios.post(generator('auth.logout'));
    } catch (e) { }

    commit(types.LOGOUT);
  },
};
