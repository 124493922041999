import Vue from 'vue';
import moment from "moment";

Vue.filter('date', (date) => {
  if  (! date)  {
    console.error('No date passed');

    return '';
  }

  return moment(date).format('DD/MM/YYYY');
});

Vue.filter('datetime', (datetime, format) => {
  if  (! datetime)  {
    console.error('No datetime passed');

    return '';
  }

  return moment(datetime).format(format ?? 'DD/MM/YYYY HH:mm');
});

Vue.filter('time', (time) => {
  if  (! time)  {
    console.error('No time passed');

    return '';
  }

  return moment(time).format('HH:mm');
});

Vue.filter('to', (to) => {
  if  (! to)  {
    console.error('No to passed');

    return '';
  }

  return moment().to(to);
});

Vue.filter('truncate', function (value, size) {
  if (!value) return '';
  value = value.toString();

  if (value.length <= size) {
    return value;
  }

  return value.substr(0, size) + '...';
});
