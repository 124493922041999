export default {
  user: state => state.user,
  isLoggedIn: state => state.user !== null,
  isEmailVerified: (state) => {
    if (state.user === null) {
      return false;
    }

    try {
      return state.user.is_email_verified === true;
    } catch (e) {
      console.error(e);
      return false;
    }
  },
  isTwoFactorEnabled: (state) => {
    if (state.user === null) {
      return false;
    }

    try {
      return state.user.is_two_factor_enabled === true;
    } catch (e) {
      console.error(e);
      return false;
    }
  },
};
