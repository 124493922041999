import { render, staticRenderFns } from "./home.vue?vue&type=template&id=5e1f6b84&"
import script from "./home.vue?vue&type=script&lang=js&"
export * from "./home.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Navbar: require('/var/www/vhosts/seomaster.app/httpdocs/components/Navbar.vue').default})
