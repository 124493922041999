//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      isLoading: false,
      query: '',
      clients: [],
    };
  },

  async created() {
    this.focus();
    await this.getClients();
  },

  methods: {
    async getClients() {
      this.isLoading = true;

      try {
        const {data} = await this.$axios.$get(this.$generator('clients.index'), {
          params: {
            'filter[name]': this.query
          }
        });

        this.clients = data;
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    },

    async goto() {
      const client = this.clients.find(x => x.name === this.query);

      if (! client) {
        return;
      }

      await this.$router.push({ name: 'clients-id-websites', params: { id: client.id } });
    },

    focus() {
      document.addEventListener('keypress', e => {
        if (e.key === '\\') {
          this.query = '';
          document.getElementById('search-field').focus();
          e.preventDefault();
        }
      });
    }
  }
});
