//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue';

export default Vue.extend({
  methods: {
    async logout () {
      await this.$store.dispatch('auth/logout');
      await this.$router.push({ name: 'index' });
    }
  }
});
