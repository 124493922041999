const routes = {
  csrf: '/sanctum/csrf-cookie',

  'auth.login': '/login',
  'auth.register': '/register',
  'auth.logout': '/logout',
  'auth.update-password': '/user/password',
  'auth.confirm-password': '/user/confirm-password',
  'auth.two-factor-authentication': '/user/two-factor-authentication',
  'auth.two-factor-recovery-codes': '/user/two-factor-recovery-codes',
  'auth.two-factor-challenge': '/two-factor-challenge',
  'auth.two-factor-qr-code': '/user/two-factor-qr-code',
  'auth.verification-notification': '/email/verification-notification',
  'auth.forgot-password': '/forgot-password',
  'auth.reset-password': '/reset-password',
  'auth.me': '/api/user',

  'clients.index': '/api/clients',
  'clients.count': '/api/clients/count',
  'clients.show': '/api/clients/{id}',
  'clients.store': '/api/clients',
  'clients.edit': '/api/clients/{id}',
  'clients.delete': '/api/clients/{id}',

  'websites.index': '/api/clients/{id}/websites',
  'websites.count': '/api/websites/count',
  'websites.show': '/api/websites/{id}',
  'websites.store': '/api/clients/{id}/websites',
  'websites.edit': '/api/websites/{id}',
  'websites.delete': '/api/websites/{id}',
  'websites.import-sitemap': '/api/websites/{id}/import-sitemap',

  'pages.index': '/api/websites/{id}/pages',
  'pages.count': '/api/pages/count',
  'pages.show': '/api/pages/{id}',
  'pages.store': '/api/websites/{id}/pages',
  'pages.edit': '/api/pages/{id}',
  'pages.delete': '/api/pages/{id}',
  'pages.generate-report': '/api/pages/{id}/lighthouse',
  'pages.mark': '/api/pages/{id}/mark',
  'pages.unmark': '/api/pages/{id}/unmark',

  'keywords.index': '/api/websites/{id}/keywords',
  'keywords.count': '/api/keywords/count',
  'keywords.show': '/api/keywords/{id}',
  'keywords.check': '/api/keywords/{id}/check',
  'keywords.store': '/api/websites/{id}/keywords',
  'keywords.edit': '/api/keywords/{id}',
  'keywords.delete': '/api/keywords/{id}',

  'backlinks.index': '/api/websites/{id}/backlinks',
  'backlinks.count': '/api/backlinks/count',
  'backlinks.show': '/api/backlinks/{id}',
  'backlinks.check': '/api/backlinks/{id}/check',
  'backlinks.store': '/api/websites/{id}/backlinks',
  'backlinks.edit': '/api/backlinks/{id}',
  'backlinks.delete': '/api/backlinks/{id}',

  'reports.index': '/api/websites/{id}/reports',
  'reports.show': '/api/reports/{id}',
  'reports.store': '/api/websites/{id}/reports',
  'reports.delete': '/api/reports/{id}',
};

const generator = function (string, params = {}) {
  let route = routes[string];

  if (route === undefined) {
    throw `Route ${string} does not exist`;
  }

  for (const [key, value] of Object.entries(params)) {
    route = route.replace('{' + key + '}', value);
  }

  return route;
};

export { routes, generator };

export default (_, inject) => {
  inject('generator', generator);
};
