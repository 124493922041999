import * as types from '../types';

export default {
  [types.FETCH_USER_SUCCESS] (state, { user }) {
    state.user = user;
  },

  [types.FETCH_USER_FAILURE] (state) {
    state.user = null;
  },

  [types.LOGOUT] (state) {
    state.user = null;
  },
};
